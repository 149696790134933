//import 'bootstrap';
import * as bootstrap from 'bootstrap';
window.bootstrap = bootstrap;
// import 'dark-mode-switch';
//import '/css/test.scss';
var double_check = true;
import './passwordstrenght.js';
import './passwordcheck.js';







const divs = document.querySelectorAll('.show-more');
const divs_l = document.querySelectorAll('.show-less');

divs.forEach(el => el.addEventListener('click', event => {
  ////console.log(event.target.getAttribute("class"));
  var n = event.target.nextSibling;
  n.classList.remove('d-none');
  n.nextSibling.classList.remove('d-none');
  event.target.classList.add('d-none');
  
  //this.classList.add('good');
}));
divs_l.forEach(el => el.addEventListener('click', event => {
  ////console.log(event.target.getAttribute("class"));
  event.target.previousSibling.classList.add('d-none');
  event.target.previousSibling.previousSibling.classList.remove('d-none');
  event.target.classList.add('d-none');
  //this.classList.add('good');
}));



/**
 * Get the value of a cookie
 * Source: https://gist.github.com/wpsmith/6cf23551dd140fb72ae7
 * @param  {String} name  The name of the cookie
 * @return {String}       The cookie value
 */
function getCookie(name) {
  let value = `; ${document.cookie}`;
  let parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}

//document.cookie = 'snack=1; path=/; max-age=${60 * 60 * 24 * 14};';
//console.log(document.cookie);


function deleteCookie(cookieName) {
  document.cookie = cookieName + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
}

function hide_news_based_on_cookies() {
  //console.log('//hide_news_based_on_cookies');
  let cks = getCookie('snack');
  //console.log(typeof cks);
  //console.log(cks);
  if (cks !== undefined && typeof cks ==='string') {
    //console.log('🍪🍪🍪🍪🍪🍪 yum yum 🍪🍪🍪🍪🍪');
    let post_id_array = cks.split(",");
    post_id_array = post_id_array.filter(Number);
    let uniqueChars = post_id_array.filter((element, index) => {
      return post_id_array.indexOf(element) === index;
    });
    let css = '';
    let count = post_id_array.length;
    //console.log('count:' + count);
    let i = 1;
    post_id_array.forEach(element => {
      //console.log('-----');
      //console.log(element);
      css += '.post-alert-' + element
      if (i < count) {
        css += ',';
      }
      i++;
    });
    css = css + '{display:none !important;}'
    //console.log(uniqueChars);
    document.head.innerHTML += '<style>'+css +'</style>';
  } else {
    //console.log('//no 🍪 found');
  }
  //deleteCookie('snack');
}


document.addEventListener("DOMContentLoaded", function() {
  hide_news_based_on_cookies();

  const myAlert = document.getElementById('latestAlert');
  //// console.log('typeof myAlert');
  //// console.log(typeof myAlert);
  if (typeof (myAlert) != 'undefined' && myAlert != null) {
   
    let c = getCookie('snack');
    //if (c == undefined) {
    ////console.log(typeof c);
    ////console.log(c);
    //}
    myAlert.addEventListener('closed.bs.alert', event => {
      let post_id = myAlert.getAttribute('data-post-id');
      let current_cookie;
      if (typeof post_id !== 'undefined') {
        if (c == undefined) {
          ////console.log('it is undefined');
          current_cookie = '';
        }else{
          current_cookie = c;
          ////console.log('it is NOT undefined');
        }
        let new_cookie = current_cookie + post_id + ',';
        ////console.log(new_cookie);
        document.cookie = 'snack =' + new_cookie + '; path=/; max-age=${60 * 60 * 24 * 14};';
      } else {
        ////console.log('delete');
         
      }
      
    })
  }

  var to = setTimeout(function () {
    document.querySelector("body").classList.remove('begin-face');
  }, 100);
  var to2 = setTimeout(function () {
    document.querySelector("body").classList.remove('end-face');
  }, 700);
  //document.querySelectorAll("#wpadminbar a").forEach(item=>item.setAttribute('data-barba-prevent','self'));
  var myOffcanvas = document.getElementById('navbarOffcanvasMd')
  var bsOffcanvas = new bootstrap.Offcanvas(myOffcanvas);

  // Enabling all the tooltips on the page.
  const tooltipElements =
    document.querySelectorAll('[data-bs-toggle="tooltip"]');
  const tooltips =
    [...tooltipElements].map(el => new bootstrap.Tooltip(el));
  
  const textPath = document.querySelector("#text-path");
  if (typeof (textPath) != 'undefined' && textPath != null) {
    const h = document.documentElement,
      b = document.body,
      stt = 'scrollTop',
      sh = 'scrollHeight',
      offset = 0;

    document.addEventListener("scroll", e => {
      let percent = (h[stt] || b[stt]) / ((h[sh] || b[sh]) - h.clientHeight) * 100;
      textPath.setAttribute("startOffset", (-percent * 40) + offset)
    });
  }





});

//console.log(process.env.NODE_ENV);
if (process.env.NODE_ENV !== 'production') {
  console.log('Looks like we are in development mode');
}else{
  //console.log('Looks like we are in production mode');
}
 







