class Typer {
  constructor(element) {
    this.element = element;
    this.queue = [];
    this.text = '';
    this.cursor = '<span id="cursor"></span>';
    this.onComplete = null;
    this.typingSpeed = 65; // Default typing speed
    this.removeSpeed = 25; // Default remove speed
    this.initialQueue = []; // Store the initial sequence for infinite replay
  }

  setTypingSpeed(speed) {
    this.typingSpeed = speed;
    return this;
  }

  setRemoveSpeed(speed) {
    this.removeSpeed = speed;
    return this;
  }

  type(text, options = {}) {
    const { speed = this.typingSpeed, callback = null } = options;
    this.queue.push(() => this._typeText(text, speed, callback));
    this.initialQueue.push({ type: 'type', text, options }); // Store action details for reset
    return this;
  }

  _typeText(text, speed, callback) {
    let i = 0;
    this.element.setAttribute('data-text', text); // Set the data-text attribute to the current text
    const interval = setInterval(() => {
      if (i < text.length) {
        this.text += text.charAt(i);
        this.element.innerHTML = this.text + this.cursor;
        i++;
      } else {
        clearInterval(interval);
        if (callback) callback();
        this._next();
      }
    }, speed);
  }

  pause(duration, options = {}) {
    const { callback = null } = options;
    this.queue.push(() => this._pause(duration, callback));
    this.initialQueue.push({ type: 'pause', duration, options }); // Store action details for reset
    return this;
  }

  _pause(duration, callback) {
    setTimeout(() => {
      if (callback) callback();
      this._next();
    }, duration);
  }

  remove(count, options = {}) {
    const { speed = this.removeSpeed, callback = null } = options;
    this.queue.push(() => this._removeText(count, speed, callback));
    this.initialQueue.push({ type: 'remove', count, options }); // Store action details for reset
    return this;
  }

  _removeText(count, speed, callback) {
    let i = 0;
    const interval = setInterval(() => {
      if (i < count && this.text.length > 0) {
        this.text = this.text.slice(0, -1);
        this.element.innerHTML = this.text + this.cursor;
        i++;
      } else {
        clearInterval(interval);
        if (callback) callback();
        this._next();
      }
    }, speed);
  }

  _next() {
    if (this.queue.length > 0) {
      const nextAction = this.queue.shift();
      nextAction();
    } else if (this.onComplete) {
      this.onComplete();
    }
  }

  start(callback) {
    if (callback) {
      this.onComplete = callback;
    }
    this._next();
  }

  reset() {
    this.queue = []; // Clear the current queue
    this.text = ''; // Clear the text
    this.element.innerHTML = this.cursor; // Reset the element to show only the cursor

    // Re-create the initial queue actions
    this.initialQueue.forEach(action => {
      if (action.type === 'type') {
        this.type(action.text, action.options);
      } else if (action.type === 'pause') {
        this.pause(action.duration, action.options);
      } else if (action.type === 'remove') {
        this.remove(action.count, action.options);
      }
    });

    return this;
  }

  // New method to handle an array of texts
  typeSequenceFromArray(texts, pauseDuration, options = {}) {
    texts.forEach(text => {
      this.type(text, options);
      this.pause(pauseDuration, options);
      this.remove(text.length, options);
    });
    return this; // Return 'this' to allow further chaining
  }
}
let textDisplay = document.getElementById('text-display-1');

if (textDisplay) {

  const typer1 = new Typer(document.getElementById('text-display-1'));
  typer1
    .type("www.")
    .start(() => {
      console.log('Typer 1 is done!');
      document.getElementById('text-display-1').classList.add('done');
    });

  // Usage with continuous replay
  const typer2 = new Typer(document.getElementById('text-display-2'));
  let defaultPause = 600;

  // Array of texts to type, pause, and remove
  const activities = [
    "Salsa", "Hobo", "Cello", "Muziektheorie", "Portretschilderen", "Korte verhalen",
    "Portrettekenen", "Edelsmeden", "Blokfluit", "Saxofoon", "Modeltekenen", "Urban",
    "Gitaar akoestisch", "Kleuterdans", "Bandcoaching", "Muziektheater", "Trompet",
    "Folklore", "Viool", "Slagwerk", "DJ-EN", "Boetseren", "AMV", "Piano & Toetsen", "Tapdans",
    "Modelschilderen", "Musical", "Muziekvereniging", "Accordeon", "Gitaar elektrisch",
    "Poëzie", "Cabaret", "Mondharmonica", "Film", "Harp", "Afrikaanse dans", "Fagot", "Buikdansen", "Beeldhouwen", "Jazzballet", "Hoorn", "High Heels",
    "Hiphop", "Percussie", "Basgitaar", "Peuterdans", "Biodanza", "Klassiek ballet", "Tekenen",
    "Spitzen", "Textiel / kleding", "Vocaal theater", "Seniorendans", "Big Bands", "Contrabas",
    "Leerbewerking", "Dwarsfluit", "Kunstgeschiedenis", "Dichten", "Bachata", "Dancecrew",
    "Peuters & Kleuters", "toneelspelen", "Zilversmeden", "Klarinet", "Grafische technieken",
    "Breakdance", "Trombone", "Pulp", "Goudsmeden", "Keramiek", "Digitaal", "Ukulele",
    "Muziekproductie", "Fotografie", "Streetdance", "Koor ", "Drums", "Papier maken", "Dansconditie",
    "Schilderen", "RapPEN", "Zang", "Tango", "Musical", "Circus"
  ];

  const texts = activities.sort(() => Math.random() - 0.5);

  let so = setTimeout(function () {
    typer2
      .typeSequenceFromArray(texts, defaultPause) // Use the new method with the array
      .start(() => {
        console.log('Typer 2 is done!');
        // Replay the sequence indefinitely
        setTimeout(() => {
          typer2.reset();
          typer2.start();
        }, defaultPause); // Add a delay before replaying
      });
  }, 600);
}
document.addEventListener("DOMContentLoaded", () => {
  let text = document.getElementById('text-display-2');
  text.addEventListener("click", () => {
    location.href = '/ikleer-je/?ikleerje=' + text.getAttribute('data-text') + '#zoeken';
  });
});
