
import Swiper from 'swiper/bundle';
//import Swiper from 'swiper';
//* import the Swiper styles
// import 'swiper/css';


// // import styles bundle
import 'swiper/css/bundle'; // alles!
// import 'swiper/css'; // core!
// import 'swiper/css/pagination'; // core!


let swiper;
function innitSwiper() {

    swiper = new Swiper('.disc-mobile', {
        // Optional parameters
        //direction: 'vertical',
        slidesPerView: 'auto',
        loop: false,
        autoHeight: true,
        //wrapperClass: 'wp-block-gallery',
        //slideClass: 'wp-block-image',
        //containerModifierClass:'wp-block-gallery',
        // If we need pagination
        // centeredSlidesBounds: true,
        // centeredSlides: true,

        pagination: {
            el: '.swiper-pagination',
            clickable: true,
            dynamicBullets: true,
            dynamicMainBullets: 3,
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        //cssMode:true,
        keyboard: {
            enabled: true, // Enable keyboard control
        },
        // Navigation arrows
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },


        // And if we need scrollbar
        // scrollbar: {
        //     el: '.swiper-scrollbar',
        //     draggable: true
        // },
        on: {
            init: function () {
                // Swiper is ready

                console.log('Swiper is ready');
                //innitBackdrop();
            }
        }
    });

    // swiper = new Swiper('.client', {

    //     loop: false,
    //     slidesPerView: 1,
    //     spaceBetween: 10,
    //     // Responsive breakpoints
    //     breakpoints: {
    //         // when window width is >= 320px
    //         600: {
    //             slidesPerView: 2,
    //             spaceBetween: 20
    //         },
    //         // when window width is >= 480px
    //         // 480: {
    //         //   slidesPerView: 3,
    //         //   spaceBetween: 30
    //         // },
    //         // when window width is >= 640px
    //         800: {
    //             slidesPerView: 3,
    //             spaceBetween: 40
    //         }
    //     },
    //     //autoHeight: true,
    //     //wrapperClass: 'wp-block-gallery',
    //     //slideClass: 'wp-block-image',
    //     //containerModifierClass:'wp-block-gallery',
    //     // If we need pagination
    //     //centeredSlidesBounds: true,
    //     //centeredSlides: true,

    //     pagination: {
    //         el: '.swiper-pagination',
    //         clickable: true,
    //         dynamicBullets: true,
    //         dynamicMainBullets: 3,
    //         nextEl: '.swiper-button-next',
    //         prevEl: '.swiper-button-prev',
    //     },
    //     //cssMode:true,
    //     keyboard: {
    //         enabled: true, // Enable keyboard control
    //     },
    //     // Navigation arrows
    //     navigation: {
    //         nextEl: '.swiper-button-next',
    //         prevEl: '.swiper-button-prev',
    //     },


    //     // And if we need scrollbar
    //     scrollbar: {
    //         el: '.swiper-scrollbar',
    //         draggable: true
    //     },
    //     on: {
    //         init: function () {
    //             // Swiper is ready

    //             console.log('Swiper is ready');
    //             //innitBackdrop();
    //         }
    //     }
    // });




    // Function to go to a specific slide



    // Example usage:
    //goToSlide(5); // Go to slide index 2
    //innitBackdrop();
}


innitSwiper();


document.addEventListener('DOMContentLoaded', function () {
    const showMoreLinks = document.querySelectorAll('.show-more');

    showMoreLinks.forEach(function (showMoreLink) {
        showMoreLink.addEventListener('click', function (e) {
            e.preventDefault();

            // Find the closest `.more-terms` element relative to the clicked `.show-more`
            const hiddenTerms = showMoreLink.closest('.ilj_disciplines').querySelector('.more-terms');
            if (hiddenTerms) {
                const isHidden = hiddenTerms.style.display === 'none' || hiddenTerms.style.display === '';
                hiddenTerms.style.display = isHidden ? 'inline' : 'none';
                showMoreLink.textContent = isHidden ? 'Toon minder' : 'Toon meer';
            }
        });
    });
});





document.addEventListener("DOMContentLoaded", () => { 
    //focus the search fiels
    const searchField = document.querySelector('body.ikleer-je .search-field');
    if (searchField) {
        searchField.focus();
    }

    // const swiper = new Swiper('.swiper', {
    //     speed: 400,
    //     spaceBetween: 100,
    // });

    

    const swiper = new Swiper();

    
});